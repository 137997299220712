<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="400"
    scrollable
    persistent
  >
    <ImportExportForm
      :imported-exported-item="exportedItem"
      :errors="orderValidationErrors"
      :disabled="$store.getters.loading['post:api/orders/export']"
      @clear:errors="clearOrderValidationErrors"
      @save="exportOrder"
      @cancel="goToOrdersPage"
    />
  </v-dialog>
</template>

<script>
import ImportExportForm from '@/components/ImportExportForm';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ExportOrders',

  components: { ImportExportForm },

  data() {
    return {
      exportedItem: {},
    };
  },

  computed: {
    ...mapState('orders', ['orderValidationErrors', 'orderFilterParams']),
  },

  methods: {
    ...mapActions('orders', ['exportOrders', 'clearOrderValidationErrors']),

    exportOrder(exportedOrder) {
      this.exportOrders(exportedOrder).then(() => this.goToOrdersPage());
    },

    goToOrdersPage() {
      this.$router.push({ name: 'orders', query: this.orderFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
